export const products = [
  {
    category: "Cables",
    path: "/cables",
    subcategory: [
      { name: "AOC", path: "/cables/aoc" },
      { name: "HDMI", path: "/cables/hdmi" },
      { name: "HDMI Certified", path: "/cables/hdmi-certified" },
      { name: "USB", path: "/cables/usb" },
    ],
  },
  {
    category: "Control",
    path: "/control",
    subcategory: [{ name: "Extenders", path: "/control/extenders" }],
  },
  {
    category: "Converters",
    path: "/converters",
    subcategory: [
      { name: "Audio", path: "/converters/audio" },
      { name: "Component", path: "/converters/component" },
      { name: "Composite", path: "/converters/composite" },
      { name: "DVI", path: "/converters/dvi" },

      { name: "HDMI", path: "/converters/hdmi" },

      { name: "SDI", path: "/converters/sdi" },
      { name: "VGA", path: "/converters/vga" },
    ],
  },

  {
    category: "Extenders",
    path: "/extenders",
    subcategory: [
      { name: "Fiber Optic", path: "/extenders/fiber-optic" },
      { name: "HDBaseT", path: "/extenders/hdbaset" },
      { name: "HDMI", path: "/extenders/hdmi" },

      { name: "USB", path: "/extenders/usb" },

      { name: "VGA", path: "/extenders/vga" },
      { name: "Wall Plates", path: "/extenders/wall-plates" },
    ],
  },

  {
    category: "NetworkAV",
    path: "/networkav",
    subcategory: [
      { name: "H.264", path: "/networkav/h.264" },
      { name: "SDVoE", path: "/networkav/sdvoe" },
    ],
  },

  {
    category: "SDI",
    path: "/sdi",
    subcategory: [
      { name: "Converters", path: "/sdi/converters" },
      { name: "Extenders", path: "/sdi/extenders" },
      { name: "Splitters", path: "/sdi/splitters" },
    ],
  },

  {
    category: "Splitters",
    path: "/splitters",
    subcategory: [
      { name: "HDMI", path: "/splitters/hdmi" },
      { name: "HDMI Professional", path: "/splitters/hdmi-professional" },
    ],
  },

  {
    category: "Switchers",
    path: "/switchers",
    subcategory: [
      { name: "HDMI", path: "/switchers/hdmi" },
      { name: "KVM", path: "/switchers/kvm" },
      { name: "Matrix", path: "/switchers/matrix" },

      { name: "Matrix Modular", path: "/switchers/matrix-modular" },

      { name: "Matrix Modules", path: "/switchers/matrix-modules" },
      { name: "Scaler", path: "/switchers/scaler" },
    ],
  },
];

export const solutions = [
  {
    name: "Broadcast",
    path: "/applications/broadcast",
  },
  {
    name: "Classroom",
    path: "/applications/classroom",
  },
  {
    name: "Conference Rooms",
    path: "/applications/corporate",
  },
  {
    name: "Digital Signage",
    path: "/applications/signage",
  },
  {
    name: "Government",
    path: "/applications/government",
  },
  {
    name: "Hospitality",
    path: "/applications/hospitality",
  },
  {
    name: "House of Worship",
    path: "/applications/worship",
  },
  {
    name: "Residential",
    path: "/applications/residential",
  },
];

export const sales = [
  {
    name: "Sales Inquiries",
    path: "/sales",
  },
  {
    name: "Press Inquiries",
    path: "/press",
  },
];

export const contact = [
  {
    name: "Dealer's Signup",
    path: "/contact",
  },
  // {
  //   name: "Inquire Product",
  //   path: "/inquireproduct",
  // },
  {
    name: "Newsletter Signup",
    path: "/signup",
  },

  {
    name: "Tech Support",
    path: "/support",
  },
];

export const about = [
  {
    name: "Company",
    path: "/company",
  },
  {
    name: "Catalog",
    path: "/catalog",
  },
  {
    name: "News",
    path: "/media",
  },
  {
    name: "Blogs",
    path: "/blog",
  },
  {
    name: "Partners",
    path: "/partners",
  },
  {
    name: "Terms",
    path: "/company/terms",
  },
];

export const account = [
  {
    name: "My Info",
    path: "/account/info",
  },
  {
    name: "Order history",
    path: "/account/orders",
  },

  {
    name: "Open Items",
    path: "/account/open",
  },

  {
    name: "Product List",
    path: "/account/skulist",
  },
  {
    name: "Shipments",
    path: "/account",
  },

  {
    name: "Sku History",
    path: "/account/history",
  },
  // {
  //   name: "Change Password",
  //   path: "/account/password",
  // },
  {
    name: "Logout",
    path: "/logout",
  },
];

export const resource = [
  {
    name: "CA Prop 65",
    path: "/warranty/CAProp65",
  },
  {
    name: "Case Studies",
    path: "/casestudy",
  },

  {
    name: "FAQ",
    path: "/faq",
  },
  {
    name: "Warranty",
    path: "/warranty",
  },
  {
    name: "Flex Matrix",
    path: "/flexmatrix",
  },
];

export const flexMatrix = [
  {
    name: "Flex Matrix",
    path: "/flexmatrix",
    subcategory: [
      { name: "Enterprise", path: "/flexmatrix/enterprise" },
      { name: "Government", path: "/flexmatrix/government" },
      { name: "Hospitality", path: "/flexmatrix/restaurant" },
      { name: "Higher Education", path: "/flexmatrix/higherEducation" },
      { name: "Entertainment", path: "/flexmatrix/entertainment" },
      { name: "Financial", path: "/flexmatrix/financial" }
    ],
  },
];
